import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const DiscografiaPerSaeculaSaeculorumAmen = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Per Saecula Saeculorum Amen - 2015"
        titleTemplate="%s | Eduardo Ceballos"
        description="Per Saecula Saeculorum Amen - 2015"
        canonical="https://eduardoceballos.com/discografia/per-saecula-saeculorum-amen"
        openGraph={{
          url: `https://eduardoceballos.com/discografia/per-saecula-saeculorum-amen`,
          title: `Per Saecula Saeculorum Amen - 2015`,
          description: `Per Saecula Saeculorum Amen - 2015`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/discografia.jpg`,
              alt: `Per Saecula Saeculorum Amen - 2015`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Per Saecula Saeculorum Amen - 2015</h1>
      <center>
        <img
          className="discos-img"
          style={{ float: "Right", marginLeft: "10px" }}
          src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,w_auto/strapi/tapacdpersaeculasaeculorumamen_f4e170abd4.jpg"
          alt=""
        />
      </center>
      <div>
        <p>
          El autor como un acróbata, vislumbra el mundo que lo circunda
          observando todo el universo.
        </p>
        <p>
          Creció en sus saberes, con los filósofos griegos, primero, y luego con
          los pensadores de todos los tiempos, en la búsqueda de la verdad.
        </p>
        <p>
          Como un alquimista nos introduce a un mundo nuevo, donde convergen
          todas las ciencias, que nos nutren con su saber, lo que hace con un
          leguaje poético para llegar a ese mágico mundo de materia física,
          química, que fluyen en nuestra sangre, transmitiéndonos sentimientos y
          sensaciones nuevas, que nos enseña a encontrar el camino. Trata de
          enseñarnos a trabajar para crear un mundo nuevo, fundamentando en la
          soberanía de la vida, para dibujar el progreso y la alegría en un
          camino abierto hacia la luz.
        </p>
        <p>
          Invita a la humanidad a producir un abrazo cultural y científico con
          la vida, haciendo un inventario para comprender mejor el mundo,
          jugando con la luz en una danza vital que transforma las energías.
        </p>
        <p>Su Terra Nova.</p>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default DiscografiaPerSaeculaSaeculorumAmen
